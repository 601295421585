<template>
    <nav class="navbar">
        <div class="logo">
            <router-link to="/" class="logo text-white">Crédito Unicco</router-link>
        </div>
        <nav id="menu">
            <button class="nav-mobile" id="nav-mobile" @click="openMenu" :class="navOpen">
                <span></span>
                <span></span>
                <span></span>
            </button>
            <ul class="nav-menu" :class="navbarClass">
                <li class="nav-item">
                    <router-link to="/credito" class="nav-link">Solicitar crédito </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/solicitud" class="nav-link">Mis solicitudes</router-link>
                </li>
                <li class="nav-item dropdown">
                    <a @click="dropdown($event)" href="#" class="nav-link drop-menu">Usuario</a>
                    <div class="dropdown-content text-center">
                        <button class="button alternate" @click="salir"><i class="fa-solid fa-right-from-bracket"></i>Cerrar sesión</button>
                    </div>
                </li>
            </ul>
        </nav>
    </nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'NavBar',
    data() {
        return {
            navbarClass: '',
            navOpen: '',
        };
    },
    computed: {
        ...mapGetters(['user', 'isAuthenticated'])
    },
    methods: {
        ...mapActions(['logout']),
        async salir() {
            this.$loading({ text: 'Cerrando sesión', classLoad: 'load-info' })
            try {
                await this.logout();
                this.$loadingClose();
                this.$router.push({ path: '/login' }).then(() => { this.$router.go(0) });
            } catch (error) {
                console.error('Logout failed:', error);
                this.$loadingClose();
            }
        },
        openMenu(event) {
            if (!event.currentTarget.classList.contains('nav-open')) {
                this.navbarClass = 'open-menu'
                this.navOpen = 'nav-open'
                return
            }
            this.removerClase()
        },
        removerClase() {
            this.navbarClass = ''; // Resetear la clase
            this.navOpen = ''; // Resetear la clase
        },
        dropdown(event) {
            event.preventDefault();
        },
    },
    watch: {
        $route(to, from) {
            // Remover clase cuando cambia la ruta
            this.removerClase();
        }
    }
}

</script>

<style scoped>
/* ================================================================================================= //
// -------------------------------------------- Navbar --------------------------------------------- //
// ================================================================================================= */
.navbar {
    background: linear-gradient(5deg, #009033 0%, #a7e061 145%);
    height: 60px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    /* padding: 0 .75rem; */
    box-sizing: border-box;
    border-bottom: 2px solid var(--primary);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo {
    float: left;
    padding: 6px 20px;
    width: auto;
}
.logo a {
    display: block;
    text-decoration: none;
    text-align: center;
    color: #fff;
    font-weight: bold;
    line-height: 44px;
    font-size: 1.15rem;
}
#menu {
    position: absolute;
    top: 60px;
    width: 100%;
}
#menu ul {
    max-height: 0;
    overflow: hidden;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 14px;
}
#menu li {
    display: block;
    background: #33363b;
    text-align: center;
    border-bottom: 1px solid #282b30;
}
#menu li a {
    display: block;
    color: #fff;
    text-decoration: none;
    line-height: 60px;
    padding: 0 26px;
    font-size: .95rem
}
.navbar .nav-item .nav-link:hover {
    background-color: var(--primary);
}
#menu .open-menu {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    max-height: 400px;
    transition: max-height 0.4s ease;
}
#nav-mobile {
    background: transparent;
    border: 0;
    cursor: pointer;
    float: right;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    top: -60px;
    opacity: 0.6;
}
#nav-mobile.nav-open {
    opacity: 1;
}
#nav-mobile span {
    display: block;
    width: 60%;
    margin: 4px auto;
    height: 4px;
    background: #fff;
}
.nav-item.dropdown:hover .dropdown-content {
    max-height: 1000px;
    transition: max-height 1s ease-in-out;
}
.dropdown-content {
    position: absolute;
    background-color: #f8f8f8;
    min-width: 7.5rem;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(48, 48, 48, 0.5);
    z-index: 1;
    width: 170px;
    border-radius: 0.5rem;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height .5s cubic-bezier(0, 1, 0, 1);
}
.dropdown-content a {
    color: black;
    padding: .75rem 1rem;
    text-decoration: none;
    display: block;
    text-align: left;
}
.dropdown-content a:hover {
    color: var(--white);
    background-color: var(--primary);
}

@media only screen and (min-width: 768px) {
    #nav-mobile {
        display: none;
    }
    #menu {
        width: auto;
        float: right;
        top: 0;
        position: relative;
    }
    #menu .open-menu {
        box-shadow: none;
    }
    #menu ul {
        max-height: inherit;
    }
    #menu li {
        float: left;
        border: 0;
        background: transparent;
    }
    #menu li a {
        display: block;
        color: #fff;
        padding: 0 26px;
    }
}
@media (max-width: 768px) {
    .dropdown-content{
        width: 100%;
    }
}
</style>