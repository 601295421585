<template>
    <NavBar v-if="showGlobalComponent" />
    <WhatsappMsg />
    <LoadingPage />
    <router-view />
</template>

<script>

export default {
    data() {
        return {}
    },
    computed: {
        showGlobalComponent() {
            // Ocultar el componente en la ruta de login
            return (this.$route.name !== 'login' && this.$route.name !== 'NotFound' && this.$route.name !== 'index' && this.$route.name !== 'recuperarPassword');
        }
    },
    created() {
        
    },
    methods: {

    }
}

</script>