import { createRouter, createWebHashHistory } from 'vue-router'
import store from '../store';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/pages/HomeView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/solicitud',
        name: 'solicitud',
        component: () => import('../views/pages/solicitud/SolicitudView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/solicitud/expediente/:id',
        name: 'expediente',
        component: () => import('../views/pages/solicitud/ExpedienteView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/solicitud/firma/:id',
        name: 'firma',
        component: () => import('../views/pages/solicitud/FirmaDocumentoView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/solicitud/descargaDocumento/:id',
        name: 'descargaDocumento',
        component: () => import('../views/pages/solicitud/DescargaDocumentoView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/solicitud/reenvio/:id',
        name: 'reenvio',
        component: () => import('../views/pages/solicitud/ReenvioView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/credito',
        name: 'credito',
        component: () => import('../views/pages/CreditoView.vue'),
        meta: { requiresAuth: true }
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/auth/DashboardView.vue'),
        meta: { guestOnly: true }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/auth/LoginView.vue'),
        meta: { guestOnly: true }
    },
    {
        path: '/recuperarPassword',
        name: 'recuperarPassword',
        component: () => import('../views/auth/RecuperarPasswordView.vue'),
        meta: { guestOnly: true }
    },
]

const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes
})

// Navigation guard to check for auth
router.beforeEach((to, from, next) => {
    const isAuthenticated = store.getters.isAuthenticated;
    if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
        return next('/index');
    } 
    if (to.matched.some(record => record.meta.guestOnly) && isAuthenticated) {
        return next('/');
    } 
    return next();
    
});

export default router
