import { createStore } from 'vuex'

export default createStore({
    state: {
        user: JSON.parse(localStorage.getItem('user')) || null
    },
    getters: {
        isAuthenticated: state => !!state.user,
        user: state => state.user
    },
    mutations: {
        setUser(state, user) {
            state.user = user;
            localStorage.setItem('user', JSON.stringify(user));
        },
        logout(state) {
            state.user = null;
            localStorage.removeItem('user');
        }
    },
    actions: {
        login({ commit }, user) {
            commit('setUser', user);
        },
        logout({ commit }) {
            commit('logout');
        }
    },
    modules: {
    }
})
