<template>
    <div id="loader">
        <div class="text-center">
            <div class="spinner"></div>
            <p class="text-load"></p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LoadingPage'
}

</script>
<style scoped>
#loader{
    display: none;
}
#loader.load-info, #loader.load-page{
    display: flex;
}
.load-info,
.load-page {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    align-items: center;
    justify-content: center;
    transition: opacity 0.75s, visibility 0.75s;
    opacity: 0;
    visibility: hidden;
    user-select: none;
}

.load-info {
    background-color: rgba(0, 0, 0, 0.785);
}

.load-page {
    background-color: var(--white);
}
.text-load{
    margin-top: .75rem;
    font-size: 1.25rem;
    font-weight: 600;
}
.load-info .text-load{
    color: var(--white);
}
.load-page .text-load{
    color: var(--text-color);
}

.load-info.show,
.load-page.show {
    opacity: 1;
    visibility: initial;
}

.load-info .spinner,
.load-page .spinner {
    width: 6rem;
    padding: 8px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: var(--primary);
    --_m:
        conic-gradient(#0000 10%, #000),
        linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: s3 .85s infinite linear;
    margin: 0 auto;
}

@keyframes s3 {
    to {
        transform: rotate(1turn)
    }
}
</style>