import { fetchJS, services, base_url } from '@/utilities/general'
import Swal from 'sweetalert2';

export default {
    install(app) {
        app.config.globalProperties.$base_url = base_url;
        app.config.globalProperties.$fetchJS = fetchJS;
        app.config.globalProperties.$services = services;

        /**
         * Muestra elemento de carga datos/pagina
         * @param {Object} param0 
         * @returns 
         */
        app.config.globalProperties.$loading = ({ isShow = true, classLoad = 'load-info', text = 'Validando...', button = null, isDisabled = true }) => {
            const loader = document.querySelector('#loader')

            if (button) {
                button.disabled = isDisabled
            }

            const body = document.querySelector('body');
            if (!isShow) {
                loader.classList.remove("show");
                loader.classList.remove("load-info");
                loader.classList.remove("load-page");
                loader.querySelector('.text-load').innerText = '';
                body.removeAttribute('style')
                return
            }
            
            if (loader && isShow) {
                body.style.overflow = 'hidden';
                loader.classList.add(`${classLoad}`)
                loader.classList.add("show");
                loader.querySelector('.text-load').innerText = text;
            }
        };
        app.config.globalProperties.$loadingClose = () => {

            const loader = document.querySelector('#loader');

            if (!loader) {
                return;
            }

            app.config.globalProperties.$loading({ isShow: false });
        };

        app.config.globalProperties.$validateForm = (data, label, is_number = false) => {
            const required = []
            for (let item in label){
                if (data[item] == '' || (is_number && data[item] <= 0)) {
                    required.push({'input': item, 'label':  label[item]})
                }

            }
            return required;
        }

        /**
         * Muestra la descripción de los errores en los inputs
         * @param {Object} param0 
         */
        app.config.globalProperties.$showErrors = ({msgError,  dataInput = '.form-input', is_required = true}) => {
            const required = is_required ? ' es requerido' : ''
            const result = Array.isArray(msgError);

            if(result) {
                msgError.forEach(item => {
                    const textRequired = item.not_required ? '' : required
                    const label = document.querySelector(`${dataInput} [data-error=${item.input}]`)
                    label.innerText = item.label + textRequired 
                    label.classList.add('show');
                });
                return
            }

            for (const key in msgError) {
                let label = document.querySelector(`${dataInput} [data-error="${key}"]`)
                label.innerText = msgError[key]
                label.classList.add('show');
            }


        }

        /**
         * Elimina todos los mensajes de error de los inputs
         * @param {String} dataInput 
         */
        app.config.globalProperties.$removeErrors = (dataInput = '.form-input') => {
            let errorTAg = document.querySelectorAll(`${dataInput} [data-error]`);
            errorTAg.forEach(element => element.classList.remove('show'));
        }

        /**
         * Muestra componente Modal
         * @param {Object} param0 
         * @returns 
         */
        app.config.globalProperties.$modal = ({ id = '', isShow = true }) => {
            const modal = document.querySelector(`#${id}`)

            if (!modal) {
                return
            }

            const navbar = document.querySelector('.navbar'), 
            body = document.querySelector('body');

            if (isShow) {
                modal.classList.add('show')
                navbar && navbar.classList.add('deep');
                body.style.overflow = 'hidden';
                return
            }
            navbar && navbar.classList.remove('deep');
            modal.classList.remove('show')
            body.removeAttribute('style')
        }
        
        /**
         * Muestra una alerta por un tiempo
         * @param {Object} param0 
         * @returns promise
         */
        app.config.globalProperties.$notification = async ({id = 'alert', text = '', type = 'succes', time = 5000}) => {
            const alert = document.querySelector(`#${id}`);
            window.scrollTo(0,0);
            
            const typeAlert = {
                'success':  {title: 'Exitoso', icon: '<i class="fa-solid fa-circle-check"></i>'},
                'info': {title: 'Información', icon: '<i class="fa-solid fa-circle-info"></i>'},
                'warning': {title: 'Advertencia', icon: '<i class="fa-solid fa-triangle-exclamation"></i>'},
                'danger': {title: 'Error', icon: '<i class="fa-solid fa-xmark"></i>'},
            }
            
            if (!typeAlert[type]) {
                return
            }

            alert.classList.add(type)
            alert.querySelector('.type').innerText = typeAlert[type].title
            alert.querySelector('.text').innerText = text
            
            const i = document.querySelector(`#${id} .icon`)
            i.innerHTML = typeAlert[type].icon

            alert.classList.remove('hide');
            alert.classList.add('show');
            return new Promise(resolve => setTimeout(() => {
                alert.classList.remove('show');
                alert.classList.add('hide')
                resolve()
            }, time));
            
        }

        app.config.globalProperties.$alert = ({type = 'success', text = '', title = '', html = false}) => {

            const data = {
                'success' : {
                    icon : 'success',
                    confirmButtonText : 'Aceptar',
                    confirmButton : 'button success'
                },
                'error' : {
                    icon : 'error',
                    confirmButtonText : 'Aceptar',
                    confirmButton : 'button danger'
                },
                'warning' : {
                    icon : 'warning',
                    confirmButtonText : 'Aceptar',
                    confirmButton : 'button warning'
                },
                'info' : {
                    icon : 'info',
                    confirmButtonText : 'Aceptar',
                    confirmButton : 'button info'
                }
            }

            const alert = {
                icon:  data[type].icon,
                title: title,
                text: text,
                confirmButtonText: data[type].confirmButtonText,
                customClass: {
                    confirmButton: data[type].confirmButton
                },
                buttonsStyling: false
            }

            html && (alert['html'] = html)

            return Swal.fire(alert);
        }

        /**
         * Contador para cuenta regresiva
         * @param {string} selector 
         * @returns 
         */
        app.config.globalProperties.$count = selector => {
            const timeCode = document.querySelector(`${selector}`);

            let seconds = localStorage.getItem('timeCode')
            timeCode.innerText = seconds;

            let minutes = 0;
            let remainingSeconds = 0;
            if (seconds > 0) {
                const interval = setInterval(() =>{
                    minutes = Math.round((seconds - 30)/60);
                    remainingSeconds = seconds % 60; 

                    if (remainingSeconds < 10) { 
                        remainingSeconds = "0" + remainingSeconds; 
                    }
                    timeCode.innerText = minutes + ":" + remainingSeconds; 

                    if (seconds == 0) { 
                        clearInterval(interval); 
                        const label = document.querySelector('.send-code')
                        label.classList.remove('hidden')
                    } else { 
                        seconds--; 
                    } 
                    localStorage.setItem('timeCode', seconds);

                }, 1000)
                return interval
            }
        }

        /**
         * Ejecutar peticiones
         * @param {String} url 
         * @param {String} method 
         * @param {Object} body 
         * @returns 
         */
        app.config.globalProperties.$request = async (url, method = 'GET', body = null) => {
            const response = await fetchJS({
                url: url,
                redirect: "follow",
                method: method,
                body: body
            });
            return response
        } 
        
        /**
         * Dar formato YYYY-MM-DD a fecha
         * @param {String} date 
         * @returns 
         */
        app.config.globalProperties.$formatDate = (date, tipo = 1) => {
            if (!date) {
                return '';
            }
            const d = new Date(date),
                year = d.getFullYear();

            let month = '' + (d.getMonth() + 1),
                day = '' + d.getDate();

            if (month.length < 2) month = '0' + month;
            if (day.length < 2) day = '0' + day;

            if (tipo == 1) {
                return [year, month, day].join('-');
            }
            return [day, month, year].join('/');

        }

        app.config.globalProperties.$convertDateFormat = dateString => {
            // Divide la cadena en día, mes y año
            const [day, month, year] = dateString.split('/');
            
            // Reorganiza en el formato yyyy-mm-dd
            return `${year}-${month}-${day}`;
        }
        app.config.globalProperties.$formatDateToDDMMYYYY = (date) => {
            const day = String(date.getDate()).padStart(2, '0'); // Obtiene el día y asegura que tenga 2 dígitos
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Obtiene el mes (sumar 1 porque los meses empiezan en 0)
            const year = date.getFullYear(); // Obtiene el año
            
            return `${day}/${month}/${year}`; // Formatea en dd/mm/yyyy
        }

        app.config.globalProperties.$formatCurrency = value => {
            return new Intl.NumberFormat('es-MX', { style: 'currency', currency: 'MXN' }).format(value);
        },

        app.config.globalProperties.$capitalizeText = text => {
            return text
              .toLowerCase() // Convierte todo el texto a minúsculas
              .split(' ') // Divide el texto en un array de palabras
              .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitaliza la primera letra de cada palabra
              .join(' '); // Une las palabras de nuevo en una cadena
        }

        app.config.globalProperties.$form = {
            "id": 111,
            "nombre": "Formulario Unicco 21012022",
            "descripcion": "Formulario Unicco 21012022",
            "origen": null,
            "eliminado": 0,
            "fecha_alta": "2022-08-03 16:27:27",
            "fecha_actualizacion": "2022-08-03 16:27:27",
            "secciones": [
                {
                    "id": 625,
                    "id_formulario": 111,
                    "id_seccion": 18,
                    "orden": 0,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 18,
                        "nombre": "FINANCIAMIENTO SOLICITADO",
                        "descripcion": "FINANCIAMIENTO SOLICITADO",
                        "nombre_mostrar": "FINANCIAMIENTO SOLICITADO",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2022-02-16 16:17:26",
                        "fecha_actualizacion": "2022-02-16 16:17:26"
                    },
                    "seccionCampos": [
                        {
                            "id": 3627,
                            "id_formulario_seccion": 625,
                            "id_campos": 21,
                            "id_lista": 0,
                            "etiqueta": "* Monto Solicitado",
                            "visible": 1,
                            "orden": 10,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "MONEDA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 21,
                                "nombre": "Monto Solicitado",
                                "descripcion": "Es el monto que esta solicitando el cliente",
                                "nombre_modelo": "SolicitudDetalle",
                                "nombre_atributo": "solicitud_detalle_monto_solicitado",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:12:07",
                                "fecha_actualizacion": "2019-06-15 17:12:07"
                            }
                        },
                        {
                            "id": 3628,
                            "id_formulario_seccion": 625,
                            "id_campos": 83,
                            "id_lista": 13,
                            "etiqueta": "* Origen de los Recursos",
                            "visible": 1,
                            "orden": 20,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 83,
                                "nombre": "Origen de los Recursos",
                                "descripcion": "Origen de los recursos de la solicitud",
                                "nombre_modelo": "SolicitudDetalle",
                                "nombre_atributo": "solicitud_detalle_origen_recursos",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:12:07",
                                "fecha_actualizacion": "2019-06-15 17:12:07"
                            }
                        }
                    ]
                },
                
                {
                    "id": 622,
                    "id_formulario": 111,
                    "id_seccion": 14,
                    "orden": 1,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 14,
                        "nombre": "INFORMACIÓN GENERAL",
                        "descripcion": "INFORMACIÓN GENERAL",
                        "nombre_mostrar": "INFORMACIÓN GENERAL",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2022-01-21 19:58:32",
                        "fecha_actualizacion": "2022-01-21 19:58:32"
                    },
                    "seccionCampos": [
                        {
                            "id": 3594,
                            "id_formulario_seccion": 622,
                            "id_campos": 6,
                            "id_lista": 0,
                            "etiqueta": "* Nombre(s)",
                            "visible": 1,
                            "orden": 5,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 6,
                                "nombre": "Nombre de Cliente",
                                "descripcion": "Nombre del cliente de una solicitud",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_nombre",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 16:59:39",
                                "fecha_actualizacion": "2019-06-15 16:59:39"
                            }
                        },
                        {
                            "id": 3595,
                            "id_formulario_seccion": 622,
                            "id_campos": 7,
                            "id_lista": 0,
                            "etiqueta": "* Apellido Paterno",
                            "visible": 1,
                            "orden": 10,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 7,
                                "nombre": "Apellido Paterno",
                                "descripcion": "Apellido paterno del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_apellido_paterno",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3596,
                            "id_formulario_seccion": 622,
                            "id_campos": 8,
                            "id_lista": 0,
                            "etiqueta": "* Apellido Materno",
                            "visible": 1,
                            "orden": 15,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 8,
                                "nombre": "Apellido materno",
                                "descripcion": "Apellido materno cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_apellido_materno",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:40",
                                "fecha_actualizacion": "2019-06-15 17:00:40"
                            }
                        },
                        {
                            "id": 3597,
                            "id_formulario_seccion": 622,
                            "id_campos": 14,
                            "id_lista": 3,
                            "etiqueta": "* Género",
                            "visible": 1,
                            "orden": 20,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 14,
                                "nombre": "Genero",
                                "descripcion": "Sexo del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_sexo",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:05:14",
                                "fecha_actualizacion": "2019-06-15 17:05:14"
                            }
                        },
                        {
                            "id": 3598,
                            "id_formulario_seccion": 622,
                            "id_campos": 15,
                            "id_lista": 0,
                            "etiqueta": "* R.F.C.",
                            "visible": 1,
                            "orden": 25,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 15,
                                "nombre": "RFC",
                                "descripcion": "RFC del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_rfc",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:06:59",
                                "fecha_actualizacion": "2019-06-15 17:06:59"
                            }
                        },
                        {
                            "id": 3599,
                            "id_formulario_seccion": 622,
                            "id_campos": 16,
                            "id_lista": 0,
                            "etiqueta": "* C.U.R.P.",
                            "visible": 1,
                            "orden": 30,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 16,
                                "nombre": "CURP",
                                "descripcion": "CURP del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_curp",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:07:28",
                                "fecha_actualizacion": "2019-06-15 17:07:28"
                            }
                        },
                        {
                            "id": 3600,
                            "id_formulario_seccion": 622,
                            "id_campos": 64,
                            "id_lista": 0,
                            "etiqueta": "FIEL (Firma electrónica avanzada) No. de Serie",
                            "visible": 1,
                            "orden": 35,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 64,
                                "nombre": "FIEL - No. de Serie",
                                "descripcion": "FIEL - No. de Serie",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_fiel",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3601,
                            "id_formulario_seccion": 622,
                            "id_campos": 11,
                            "id_lista": 5,
                            "etiqueta": "* Nacionalidad",
                            "visible": 1,
                            "orden": 40,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 11,
                                "nombre": "Nacionalidad",
                                "descripcion": "Es la nacionalidad del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_nacionalidad",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:03:09",
                                "fecha_actualizacion": "2019-06-15 17:03:09"
                            }
                        },
                        {
                            "id": 3602,
                            "id_formulario_seccion": 622,
                            "id_campos": 72,
                            "id_lista": 4,
                            "etiqueta": "* País de Residencia",
                            "visible": 1,
                            "orden": 45,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 72,
                                "nombre": "Pais de Residencia",
                                "descripcion": "Pais de residencia del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_pais_residencia",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3603,
                            "id_formulario_seccion": 622,
                            "id_campos": 45,
                            "id_lista": 8,
                            "etiqueta": "* Estado Migratorio",
                            "visible": 1,
                            "orden": 50,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 45,
                                "nombre": "Estado Migratorio",
                                "descripcion": "Estado migratorio del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_estado_migratorio",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:03:37",
                                "fecha_actualizacion": "2019-06-15 17:03:37"
                            }
                        },
                        {
                            "id": 3604,
                            "id_formulario_seccion": 622,
                            "id_campos": 13,
                            "id_lista": 0,
                            "etiqueta": "* Fecha de Nacimiento",
                            "visible": 1,
                            "orden": 55,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "CALENDARIO (DD/MM/YYYY)",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 13,
                                "nombre": "Fecha de Nacimiento",
                                "descripcion": "Fecha de nacimiento del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_fecha_nacimiento",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3605,
                            "id_formulario_seccion": 622,
                            "id_campos": 42,
                            "id_lista": 4,
                            "etiqueta": "* País de Nacimiento",
                            "visible": 1,
                            "orden": 60,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 42,
                                "nombre": "Pais de Nacimiento",
                                "descripcion": "Pais de nacimiento del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_pais_nacimiento",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3606,
                            "id_formulario_seccion": 622,
                            "id_campos": 12,
                            "id_lista": 6,
                            "etiqueta": "* Entidad Federativa de Nacimiento",
                            "visible": 1,
                            "orden": 65,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 12,
                                "nombre": "Estado de Nacimiento",
                                "descripcion": "Estado de Nacimiento del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_estado_nacimiento",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:03:37",
                                "fecha_actualizacion": "2019-06-15 17:03:37"
                            }
                        },
                        {
                            "id": 3607,
                            "id_formulario_seccion": 622,
                            "id_campos": 65,
                            "id_lista": 9,
                            "etiqueta": "* Tipo de Identificación",
                            "visible": 1,
                            "orden": 70,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 65,
                                "nombre": "Tipo de Identificacion",
                                "descripcion": "Tipo de Identificacion",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_tipo_identificacion",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3608,
                            "id_formulario_seccion": 622,
                            "id_campos": 44,
                            "id_lista": 0,
                            "etiqueta": "* Profesión / Ocupación",
                            "visible": 1,
                            "orden": 75,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 44,
                                "nombre": "Ocupacion",
                                "descripcion": "Ocupacion/Trabajo laboral del cliente persona fisica",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_ocupacion",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3609,
                            "id_formulario_seccion": 622,
                            "id_campos": 22,
                            "id_lista": 0,
                            "etiqueta": "* Código Postal - Direccion",
                            "visible": 1,
                            "orden": 80,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 22,
                                "nombre": "Codigo Postal",
                                "descripcion": "Codigo postal inscrito",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_codigo_postal",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3610,
                            "id_formulario_seccion": 622,
                            "id_campos": 27,
                            "id_lista": 0,
                            "etiqueta": "* Entidad Federativa",
                            "visible": 1,
                            "orden": 85,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 27,
                                "nombre": "Estado",
                                "descripcion": "Estado de la direccion que se captura",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_estado",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:19:29",
                                "fecha_actualizacion": "2019-06-15 17:19:29"
                            }
                        },
                        {
                            "id": 3611,
                            "id_formulario_seccion": 622,
                            "id_campos": 28,
                            "id_lista": 0,
                            "etiqueta": "* Alcaldia o Municipio",
                            "visible": 1,
                            "orden": 90,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 28,
                                "nombre": "Municipio",
                                "descripcion": "Municipio de la direccion",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_municipio",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:21:26",
                                "fecha_actualizacion": "2019-06-15 17:21:26"
                            }
                        },
                        {
                            "id": 3612,
                            "id_formulario_seccion": 622,
                            "id_campos": 30,
                            "id_lista": 0,
                            "etiqueta": "* Colonia",
                            "visible": 1,
                            "orden": 95,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 30,
                                "nombre": "Colonia",
                                "descripcion": "Colonia de la direccion",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_colonia",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:22:25",
                                "fecha_actualizacion": "2019-06-15 17:22:25"
                            }
                        },
                        {
                            "id": 3613,
                            "id_formulario_seccion": 622,
                            "id_campos": 26,
                            "id_lista": 0,
                            "etiqueta": "* Calle",
                            "visible": 1,
                            "orden": 100,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 26,
                                "nombre": "Calle",
                                "descripcion": "Nombre de la calle",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_calle",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:18:58",
                                "fecha_actualizacion": "2019-06-15 17:18:58"
                            }
                        },
                        {
                            "id": 3614,
                            "id_formulario_seccion": 622,
                            "id_campos": 23,
                            "id_lista": 0,
                            "etiqueta": "* Número exterior",
                            "visible": 1,
                            "orden": 105,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 23,
                                "nombre": "Numero Exterior",
                                "descripcion": "Numero exterior de la direccion",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_numero_exterior",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:17:12",
                                "fecha_actualizacion": "2019-06-15 17:17:12"
                            }
                        },
                        {
                            "id": 3615,
                            "id_formulario_seccion": 622,
                            "id_campos": 24,
                            "id_lista": 0,
                            "etiqueta": "Número interior",
                            "visible": 1,
                            "orden": 110,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 24,
                                "nombre": "Numero Interior",
                                "descripcion": "Numero interior de la direccion",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_numero_interior",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:17:43",
                                "fecha_actualizacion": "2019-06-15 17:17:43"
                            }
                        },
                        {
                            "id": 3616,
                            "id_formulario_seccion": 622,
                            "id_campos": 73,
                            "id_lista": 4,
                            "etiqueta": "* Pais de la Direccion",
                            "visible": 1,
                            "orden": 115,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 73,
                                "nombre": "Pais",
                                "descripcion": "Pais de la direccion",
                                "nombre_modelo": "SolicitudClienteDireccion",
                                "nombre_atributo": "solicitud_cliente_direccion_pais",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:22:25",
                                "fecha_actualizacion": "2019-06-15 17:22:25"
                            }
                        },
                        {
                            "id": 3617,
                            "id_formulario_seccion": 622,
                            "id_campos": 31,
                            "id_lista": 0,
                            "etiqueta": "* Telefono",
                            "visible": 1,
                            "orden": 120,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TELEFONO (MASCARA)",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 31,
                                "nombre": "Telefono Fijo",
                                "descripcion": "Telefono de casa o fijo del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_telefono_fijo",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:24:52",
                                "fecha_actualizacion": "2019-06-15 17:25:56"
                            }
                        },
                        {
                            "id": 3618,
                            "id_formulario_seccion": 622,
                            "id_campos": 32,
                            "id_lista": 0,
                            "etiqueta": "* Teléfono Celular",
                            "visible": 1,
                            "orden": 125,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TELEFONO (MASCARA)",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 32,
                                "nombre": "Telefono Celular",
                                "descripcion": "Telefono celular del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_telefono_celular",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:25:24",
                                "fecha_actualizacion": "2019-06-15 17:25:24"
                            }
                        },
                        {
                            "id": 3619,
                            "id_formulario_seccion": 622,
                            "id_campos": 18,
                            "id_lista": 2,
                            "etiqueta": "* Estado Civil",
                            "visible": 1,
                            "orden": 130,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 18,
                                "nombre": "Estado civil",
                                "descripcion": "Estado civil del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_estado_civil",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:09:32",
                                "fecha_actualizacion": "2019-06-15 17:09:32"
                            }
                        },
                        {
                            "id": 3620,
                            "id_formulario_seccion": 622,
                            "id_campos": 68,
                            "id_lista": 10,
                            "etiqueta": "* Régimen Conyugal",
                            "visible": 1,
                            "orden": 135,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "LISTA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 68,
                                "nombre": "Régimen Conyugal",
                                "descripcion": "Régimen Conyugal",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_regimen_conyugal",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3621,
                            "id_formulario_seccion": 622,
                            "id_campos": 81,
                            "id_lista": 0,
                            "etiqueta": "* Fecha de Vencimiento de Identificación",
                            "visible": 1,
                            "orden": 71,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "CALENDARIO (DD/MM/YYYY)",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 81,
                                "nombre": "Fecha vencimiento identificacion",
                                "descripcion": "Fecha vencimiento identificacion",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_fecha_vencimiento_identificacion",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3622,
                            "id_formulario_seccion": 622,
                            "id_campos": 20,
                            "id_lista": 0,
                            "etiqueta": "* Correo electrónico",
                            "visible": 1,
                            "orden": 145,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "EMAIL",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 20,
                                "nombre": "Correo Electronico",
                                "descripcion": "Correo electronico del cliente",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_email",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:10:41",
                                "fecha_actualizacion": "2019-06-15 17:10:41"
                            }
                        }
                    ]
                },
                {
                    "id": 619,
                    "id_formulario": 111,
                    "id_seccion": 15,
                    "orden": 2,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 15,
                        "nombre": "INFORMACION_CONYUGE",
                        "descripcion": "INFORMACIÓN DEL CÓNYUGE, CUNCUBINARIO O CONCUBINA",
                        "nombre_mostrar": "INFORMACIÓN DEL CÓNYUGE, CUNCUBINARIO O CONCUBINA",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2022-01-21 19:58:51",
                        "fecha_actualizacion": "2022-01-28 18:32:31"
                    },
                    "seccionCampos": [
                        {
                            "id": 3582,
                            "id_formulario_seccion": 619,
                            "id_campos": 69,
                            "id_lista": 0,
                            "etiqueta": "* Nombre completo del Cónyuge, Concubino(a)",
                            "visible": 1,
                            "orden": 5,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 69,
                                "nombre": "Nombre Conyugue",
                                "descripcion": "Nombre Conyugue",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_nombre_conyugue",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3583,
                            "id_formulario_seccion": 619,
                            "id_campos": 71,
                            "id_lista": 0,
                            "etiqueta": "* Fecha de Nacimiento",
                            "visible": 1,
                            "orden": 10,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "CALENDARIO (DD/MM/YYYY)",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 71,
                                "nombre": "Fecha de nacimiento Conyugue",
                                "descripcion": "Fecha de nacimiento Conyugue",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_fecha_nacimiento_conyugue",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3584,
                            "id_formulario_seccion": 619,
                            "id_campos": 70,
                            "id_lista": 0,
                            "etiqueta": "* R.F.C.",
                            "visible": 1,
                            "orden": 15,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 70,
                                "nombre": "RFC Conyugue",
                                "descripcion": "RFC Conyugue",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_rfc_conyugue",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        }
                    ]
                },
                {
                    "id": 620,
                    "id_formulario": 111,
                    "id_seccion": 16,
                    "orden": 3,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 16,
                        "nombre": "INFORMACIÓN LABORAL DEL SOLICITANTE",
                        "descripcion": "INFORMACIÓN LABORAL DEL SOLICITANTE",
                        "nombre_mostrar": "INFORMACIÓN LABORAL DEL SOLICITANTE",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2022-01-21 19:59:10",
                        "fecha_actualizacion": "2022-01-21 19:59:10"
                    },
                    "seccionCampos": [
                        {
                            "id": 3585,
                            "id_formulario_seccion": 620,
                            "id_campos": 51,
                            "id_lista": 0,
                            "etiqueta": "* Despacho en el que labora",
                            "visible": 1,
                            "orden": 5,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 51,
                                "nombre": "Nombre empresa",
                                "descripcion": "Nombre empresa",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_nombre_empresa",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3586,
                            "id_formulario_seccion": 620,
                            "id_campos": 55,
                            "id_lista": 0,
                            "etiqueta": "* Puesto que ocupa",
                            "visible": 1,
                            "orden": 10,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 55,
                                "nombre": "Puesto",
                                "descripcion": "Puesto",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_puesto",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3587,
                            "id_formulario_seccion": 620,
                            "id_campos": 53,
                            "id_lista": 0,
                            "etiqueta": "* Fecha de Ingreso",
                            "visible": 1,
                            "orden": 15,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "CALENDARIO (DD/MM/YYYY)",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 53,
                                "nombre": "Fecha de ingreso",
                                "descripcion": "Fecha de ingreso",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_fecha_ingreso",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3588,
                            "id_formulario_seccion": 620,
                            "id_campos": 59,
                            "id_lista": 0,
                            "etiqueta": "* Codigo Postal Empleo",
                            "visible": 1,
                            "orden": 20,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 59,
                                "nombre": "Codigo Postal",
                                "descripcion": "Codigo Postal trabajo",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_codigo_postal",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3589,
                            "id_formulario_seccion": 620,
                            "id_campos": 60,
                            "id_lista": 0,
                            "etiqueta": "* Estado Empleo",
                            "visible": 1,
                            "orden": 25,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 60,
                                "nombre": "Estado",
                                "descripcion": "Estado de trabajo",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_estado",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3590,
                            "id_formulario_seccion": 620,
                            "id_campos": 61,
                            "id_lista": 0,
                            "etiqueta": "* Municipio Empleo",
                            "visible": 1,
                            "orden": 30,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 61,
                                "nombre": "Municipio",
                                "descripcion": "Municipio Trabajo",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_municipio",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3591,
                            "id_formulario_seccion": 620,
                            "id_campos": 62,
                            "id_lista": 0,
                            "etiqueta": "* Colonia Empleo",
                            "visible": 1,
                            "orden": 35,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 62,
                                "nombre": "Colonia",
                                "descripcion": "Colonia Trabajo",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_colonia",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3592,
                            "id_formulario_seccion": 620,
                            "id_campos": 74,
                            "id_lista": 4,
                            "etiqueta": "* Pais Empleo",
                            "visible": 1,
                            "orden": 40,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 74,
                                "nombre": "Pais",
                                "descripcion": "Pais de Trabajo",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_pais",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        },
                        {
                            "id": 3593,
                            "id_formulario_seccion": 620,
                            "id_campos": 56,
                            "id_lista": 0,
                            "etiqueta": "* Correo electrónico",
                            "visible": 1,
                            "orden": 45,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "EMAIL",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 56,
                                "nombre": "Correo laboral",
                                "descripcion": "Correo laboral",
                                "nombre_modelo": "SolicitudClienteTrabajo",
                                "nombre_atributo": "solicitud_cliente_trabajo_correo_laboral",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:16:41",
                                "fecha_actualizacion": "2019-06-15 17:16:41"
                            }
                        }
                    ]
                },

                {
                    "id": 626,
                    "id_formulario": 111,
                    "id_seccion": 13,
                    "orden": 4,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 13,
                        "nombre": "DECLARACIONES DEL SOLICITANTE",
                        "descripcion": "DECLARACIONES DEL SOLICITANTE",
                        "nombre_mostrar": "DECLARACIONES DEL SOLICITANTE",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2022-01-21 03:52:45",
                        "fecha_actualizacion": "2022-01-21 20:01:11"
                    },
                    "seccionCampos": [
                        {
                            "id": 3629,
                            "id_formulario_seccion": 626,
                            "id_campos": 67,
                            "id_lista": 0,
                            "etiqueta": "En caso de no actuar por cuenta propia, coloca el nombre del tercero",
                            "visible": 1,
                            "orden": 40,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 67,
                                "nombre": "Nombre de Tercero",
                                "descripcion": "Nombre de Tercero",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_actua_tercero_nombre",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3630,
                            "id_formulario_seccion": 626,
                            "id_campos": 75,
                            "id_lista": 12,
                            "etiqueta": "* ¿Usted, su cónyuge, concubinario o concubina o algún familiar hasta el 2º Grado desempeñan o han desempeñado en el transcurso de los últimos doce meses algún cargo público o actividades políticas en territorio nacional o extranjero?",
                            "visible": 1,
                            "orden": 5,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 75,
                                "nombre": "Cargo Publico ¿Desempeño?",
                                "descripcion": "Cargo Publico ¿Desempeño?",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_cargo_publico_desempeno",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3631,
                            "id_formulario_seccion": 626,
                            "id_campos": 76,
                            "id_lista": 0,
                            "etiqueta": "Nombre de quien Desempeño",
                            "visible": 1,
                            "orden": 10,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 76,
                                "nombre": "Cargo Publico Nombre",
                                "descripcion": "Cargo Publico Nombre",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_cargo_publico_nombre",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3632,
                            "id_formulario_seccion": 626,
                            "id_campos": 78,
                            "id_lista": 0,
                            "etiqueta": "Parentesco",
                            "visible": 1,
                            "orden": 15,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 78,
                                "nombre": "Cargo Publico Parentesco",
                                "descripcion": "Cargo Publico Parentesco",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_cargo_publico_parentesco",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3633,
                            "id_formulario_seccion": 626,
                            "id_campos": 80,
                            "id_lista": 0,
                            "etiqueta": "Cargo",
                            "visible": 1,
                            "orden": 20,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 80,
                                "nombre": "Cargo Publico Puesto",
                                "descripcion": "Cargo Publico Puesto",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_cargo_publico_puesto",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3634,
                            "id_formulario_seccion": 626,
                            "id_campos": 79,
                            "id_lista": 0,
                            "etiqueta": "Dependencia",
                            "visible": 1,
                            "orden": 25,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 79,
                                "nombre": "Cargo Publico Dependencia",
                                "descripcion": "Cargo Publico Dependencia",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_cargo_publico_dependencia",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3635,
                            "id_formulario_seccion": 626,
                            "id_campos": 77,
                            "id_lista": 0,
                            "etiqueta": "Periodo de Ejercicio",
                            "visible": 1,
                            "orden": 30,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 77,
                                "nombre": "Cargo Publico Periodo",
                                "descripcion": "Cargo Publico Periodo",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_cargo_publico_periodo",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:04:04",
                                "fecha_actualizacion": "2019-06-15 17:04:04"
                            }
                        },
                        {
                            "id": 3636,
                            "id_formulario_seccion": 626,
                            "id_campos": 66,
                            "id_lista": 11,
                            "etiqueta": "¿Actua por cuenta propia?",
                            "visible": 1,
                            "orden": 35,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 66,
                                "nombre": "¿Actua por cuenta propia?",
                                "descripcion": "¿Actua por cuenta propia?",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_actua_cuenta_propia",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        },
                        {
                            "id": 3637,
                            "id_formulario_seccion": 626,
                            "id_campos": 82,
                            "id_lista": 0,
                            "etiqueta": "NIP",
                            "visible": 1,
                            "orden": 200,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "OCULTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 82,
                                "nombre": "NIP Buro de Crédito",
                                "descripcion": "NIP Buro de Crédito",
                                "nombre_modelo": "SolicitudCliente",
                                "nombre_atributo": "solicitud_cliente_nip_buro",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:00:11",
                                "fecha_actualizacion": "2019-06-15 17:00:11"
                            }
                        }
                    ]
                },
                {
                    "id": 628,
                    "id_formulario": 111,
                    "id_seccion": 10,
                    "orden": 5,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 10,
                        "nombre": "Datos Bancarios",
                        "descripcion": "Datos Bancarios",
                        "nombre_mostrar": "Datos Bancarios",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2021-12-19 13:17:59",
                        "fecha_actualizacion": "2021-12-19 13:17:59"
                    },
                    "seccionCampos": []
                },
                {
                    "id": 627,
                    "id_formulario": 111,
                    "id_seccion": 7,
                    "orden": 6,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 7,
                        "nombre": "Datos Personales",
                        "descripcion": "Datos Personales",
                        "nombre_mostrar": "Datos Personales",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2021-12-19 13:17:07",
                        "fecha_actualizacion": "2021-12-19 13:17:07"
                    },
                    "seccionCampos": []
                },
                {
                    "id": 623,
                    "id_formulario": 111,
                    "id_seccion": 12,
                    "orden": 7,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 12,
                        "nombre": "Informacion del Conyugue, Concubinario o Concubina",
                        "descripcion": "CONYUGUE",
                        "nombre_mostrar": "Informacion del Conyugue, Concubinario o Concubina",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2022-01-21 03:31:11",
                        "fecha_actualizacion": "2022-01-21 03:31:11"
                    },
                    "seccionCampos": []
                },
                {
                    "id": 621,
                    "id_formulario": 111,
                    "id_seccion": 8,
                    "orden": 8,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 8,
                        "nombre": "Información Laboral",
                        "descripcion": "Información Laboral",
                        "nombre_mostrar": "Información Laboral",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2021-12-19 13:17:28",
                        "fecha_actualizacion": "2021-12-19 13:17:28"
                    },
                    "seccionCampos": []
                },
                {
                    "id": 624,
                    "id_formulario": 111,
                    "id_seccion": 17,
                    "orden": 8,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 17,
                        "nombre": "INFORMACIÓN BANCARIA",
                        "descripcion": "INFORMACIÓN BANCARIA",
                        "nombre_mostrar": "INFORMACIÓN BANCARIA",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2022-01-21 20:42:47",
                        "fecha_actualizacion": "2022-01-21 20:42:47"
                    },
                    "seccionCampos": [
                        {
                            "id": 3623,
                            "id_formulario_seccion": 624,
                            "id_campos": 39,
                            "id_lista": 0,
                            "etiqueta": "* No. de Cuenta",
                            "visible": 1,
                            "orden": 15,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 39,
                                "nombre": "Numero de cuenta",
                                "descripcion": "Numero de cuenta del cliente",
                                "nombre_modelo": "SolicitudClienteBanco",
                                "nombre_atributo": "solicitud_cliente_banco_numero_cuenta",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:36:31",
                                "fecha_actualizacion": "2019-06-15 17:36:31"
                            }
                        },
                        {
                            "id": 3624,
                            "id_formulario_seccion": 624,
                            "id_campos": 38,
                            "id_lista": 7,
                            "etiqueta": "* Nombre Banco",
                            "visible": 1,
                            "orden": 5,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "LISTA_MEJORADA",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 38,
                                "nombre": "Nombre de Banco",
                                "descripcion": "Nombre del banco relacionado a un cliente cuenta",
                                "nombre_modelo": "SolicitudClienteBanco",
                                "nombre_atributo": "solicitud_cliente_banco_banco",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:36:01",
                                "fecha_actualizacion": "2019-06-15 17:36:01"
                            }
                        },
                        {
                            "id": 3625,
                            "id_formulario_seccion": 624,
                            "id_campos": 40,
                            "id_lista": 0,
                            "etiqueta": "* CLABE Interbancaria",
                            "visible": 1,
                            "orden": 10,
                            "estatus": 1,
                            "requerido": 1,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 40,
                                "nombre": "CLABE",
                                "descripcion": "CLABE del cliente",
                                "nombre_modelo": "SolicitudClienteBanco",
                                "nombre_atributo": "solicitud_cliente_banco_clabe",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:37:03",
                                "fecha_actualizacion": "2019-06-15 17:37:03"
                            }
                        },
                        {
                            "id": 3626,
                            "id_formulario_seccion": 624,
                            "id_campos": 63,
                            "id_lista": 0,
                            "etiqueta": "Sucursal",
                            "visible": 1,
                            "orden": 20,
                            "estatus": 1,
                            "requerido": 2,
                            "tipo_campo": "TEXTO",
                            "fecha_alta": "2022-08-03 16:27:27",
                            "fecha_actualizacion": "2022-08-03 16:27:27",
                            "campo": {
                                "id": 63,
                                "nombre": "Sucursal",
                                "descripcion": "Sucursal del Banco Cliente",
                                "nombre_modelo": "SolicitudClienteBanco",
                                "nombre_atributo": "solicitud_cliente_banco_sucursal",
                                "estatus": 1,
                                "fecha_alta": "2019-06-15 17:37:50",
                                "fecha_actualizacion": "2019-06-15 17:37:50"
                            }
                        }
                    ]
                },
                {
                    "id": 618,
                    "id_formulario": 111,
                    "id_seccion": 11,
                    "orden": 9,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 11,
                        "nombre": "Referencia - Conocido",
                        "descripcion": "Referencia - Conocido",
                        "nombre_mostrar": "Referencia - Conocido",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2021-12-19 13:41:21",
                        "fecha_actualizacion": "2021-12-19 13:41:21"
                    },
                    "seccionCampos": []
                },
                {
                    "id": 617,
                    "id_formulario": 111,
                    "id_seccion": 9,
                    "orden": 10,
                    "estatus": 1,
                    "fecha_alta": "2022-08-03 16:27:27",
                    "fecha_actualizacion": "2022-08-03 16:27:27",
                    "seccion": {
                        "id": 9,
                        "nombre": "Referencia Familiar",
                        "descripcion": "Referencia Familiar",
                        "nombre_mostrar": "Referencia Familiar",
                        "asignado": 0,
                        "visible": 1,
                        "repeticiones": 1,
                        "fecha_alta": "2021-12-19 13:17:42",
                        "fecha_actualizacion": "2021-12-19 13:39:54"
                    },
                    "seccionCampos": []
                }
            ]
        }
    }
};