<template>

<a class="chatbot-toggler" :href="url" target="_blank">
    <i class="fa-brands fa-whatsapp icon"></i>
</a> 
    
</template>

<script>
import { mapGetters} from 'vuex';

export default {
    name: 'WhatsappMsg',
    data() {
        return {
            url: ''
        }
    },
    async mounted() {
        if (this.isAuth()) {
            const response = await this.$request(this.$services.comunicacion_sesion())
            this.url = response.data.url
            if (response.status != 200) {
                return false;
            }
            return
        } 
        const response = await this.$fetchJS({
            url: this.$services.comunicacion_home(),
            redirect: "follow",
            method: 'GET',
        }, false);
        this.url = response.data.url

        if (response.status != 200) {
            return false;
        }
    },
    methods: {
        ...mapGetters(['isAuthenticated']),
        isAuth() {
            if (this.isAuthenticated()) {
                return true
            }
            return false
        }
    },
}
</script>

<style>
.chatbot-toggler {
    position: fixed;
    z-index: 1001;
    bottom: 1.25rem;
    right: 1rem;
    outline: none;
    border: none;
    height: 3.5rem;
    width: 3.5rem;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #52d366;
    transition: all 0.2s ease;
}
.chatbot-toggler .icon {
    color: #fff;
    position: absolute;
    font-size: 1.85rem;
    pointer-events: none;
}


</style>