/**
 * SOLO DEBERA SER PARA EVENTOS DE TECLADOS
 */

export default {
    install(app) {
        
        /**
         * EVENTOS DE TECLADO
         */

        app.config.globalProperties.$isNumber = event => {
            event = event ? event : window.event;
            /**
             * @var {Node} element objeto del html donde se coloca el evento
             * @var {int} incluye_punto si se desea que se valide con puntos debe enviarse en un data con valor 1
             * @var {int} decimales valor personalizado de decimales, inicializado en 2
             * @var {int} charCode numero asccii de la tecla capturada en evento
             * @var {int} isZero codigo ascci del cero
             */
            const element = event.target;
            const incluye_punto = +element.dataset.incluye_punto || 0;
            const decimales = +element.dataset.decimales || 2;
            const charCode = (event.which) ? event.which : event.keyCode;
            const isZero = 48;
            // si incluye punto se procede a validar el numero de decimales y el punto
            if (incluye_punto) {
                /**
                 * @var {object} deci valida si ya tiene decimales en base al punto
                 */
                const deci = element.value.split('.');
                /**
                 * si no hay decimales se inicializa con una cadena vacia
                 * @var {bool} number_decimals cuenta el numero de decimales, en caso de q sobre pase el total definido
                 * no podra escribir el usuario
                 * @var {bool} is_number indica si esta escribiendo del lado de enteros y no de decimales
                 */
                !deci[1] && (deci[1] = '');
                const number_decimals = deci[1].length <= decimales - 1;
                const is_number = element.selectionStart <= deci[0].length
                // no cumple con la validacion de los decimales y no es numero si no decimales
                if (!number_decimals && !is_number) {
                    event.preventDefault();
                    return false;
                }
                /**
                 * validacion de doble cero
                 * mas de 2 ceros no esta permitido
                 * no se puede colocar un cero al principio diferente de 0 en las primeras posiciones
                 */
                
                // si la primera posicion es cero nopermite colocar cero
                if (element.selectionStart < 2 && is_number && charCode == isZero && +element.value[0] === 0) {
                    event.preventDefault();
                    return false;
                }
                // valida si un caracter es diferente a numero
                if (charCode != 46 && charCode > 31 && (charCode < isZero || charCode > 57)) {
                    event.preventDefault();
                    return false;
                }
                // evitar colocar doble punto
                if (charCode == 46 && element.value.indexOf('.') !== -1) {
                    event.preventDefault();
                    return false;
                }
            
                return true;
            }
            // sin punto solo valida el ingreso de numeros
            if (charCode > 31 && (charCode < isZero || charCode > 57)) {
                event.preventDefault();
                return false;
            }
            return true;
        }
        app.config.globalProperties.$oneNumber = event => {
            event.preventDefault();
            const element = event.target;

            const charCode = (event.which) ? event.which : event.keyCode
            if ((charCode < 48 || charCode > 57)) {
                return false;
            }

            const valueInput = parseInt(element.dataset.value);
            const input = document.querySelector(`input[data-value="${valueInput + 1}"]`)
            element.value = event.key

            if (valueInput < 6) {
                input.select()
                input.focus()
            }

            return true
        }
        app.config.globalProperties.$viewPass = event => {
            event.preventDefault()
            const element = event.target;

            const content = element.closest(".content-section"),
            input = content.querySelector('.input'),
            icon = content.querySelector('.icon-eye')

            if (input.type == 'password') {
                input.type = 'text'
                icon.classList.remove('fa-eye-slash')
                icon.classList.add('fa-eye')
                return
            }
            input.type = 'password'
            icon.classList.add('fa-eye-slash')
            icon.classList.remove('fa-eye')
        }
    }
};