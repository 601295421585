import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import WhatsappMsg from '@/components/WhatsappMsg.vue'
import globalFunctions from '@/utilities/globalFunctions';
import globalFunctionsEvents from '@/utilities/globalFunctionsEvents';

import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css'; // Importa el estilo por defecto

import NavBar from '@/components/NavBar.vue'
import LoadingPage from '@/components/LoadingPage.vue'

import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';

import VuePdfApp from "vue3-pdf-app";
import "vue3-pdf-app/dist/icons/main.css";

// swal 2
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

let app = createApp(App)
// Registrar el componente
app.component('NavBar', NavBar)
app.component('LoadingPage', LoadingPage)
app.component('WhatsappMsg', WhatsappMsg)
app.component('MultiSelect', Multiselect)
app.component('VuePdfApp', VuePdfApp);


app
.use(store)
.use(router)
.use(globalFunctions)
.use(globalFunctionsEvents)
.use(VueTelInput)
.use(VueSweetalert2)
.mount('#app')